<template>
<div>
    <div class="row"
        v-for="(bookCounts, indx) in [ cards.find(i=>i.source == items.vocab_list) ]"
        :key="indx+'a'"
    >
        <div class="col-6 col-sm-4 col-lg-3 mx-auto container-relative pad-small">
            <picture style="position: relative;">
                <div class="fa-stack cover-icon">
                    <i class="fa fa-circle fa-stack-2x icon-background c-blue"></i>
                    <i class="fa fa-stack-1x c-white" :class="mediumIcon"></i>
                </div>
                <img
                    :src="require(`@/assets/images/covers/${items.filename ? items.filename : 'cover.jpg'}`)"
                    @error="items.filename = 'cover.jpg'"
                />
            </picture>
        </div>
        <div class="col">
            <div class="pad-big w-100 d-flex align-right" style="align-items: center;">
                <div class="std-flex d-none d-md-block"></div>
                <div
                    class="std-flex d-none d-md-block o-50"
                    v-tippy
                    content="First card created on"
                >
                {{bookCounts.created}}
                </div>
            </div>
            <div class="d-flex marg-big" style="align-items: center">
                <h2>{{items.title}}</h2>
            </div>
            <h2>{{bookCounts.cnt_vocab+bookCounts.cnt_kanji+bookCounts.cnt_trash + ' cards'}}</h2>
            <div class="d-flex w-100">
                <div class="progress w-100 marg-big">
                    <div v-tippy
                        content="Learned vocab"
                        class="progress-bar bg-purple"
                        role="progressbar"
                        style="filter:brightness(1.3)"
                        :style="`width:${ bookCounts.cnt_vocab / (bookCounts.cnt_vocab+bookCounts.cnt_kanji+bookCounts.cnt_trash) * 100 }%;`"
                    >
                    {{cards.find(i=>i.source == items.vocab_list).cnt_vocab}}
                    </div>
                    <div v-tippy
                        content="Learned kanji"
                        class="progress-bar bg-red"
                        role="progressbar" style="filter:brightness(1.3)"
                        :style="`width:${ bookCounts.cnt_kanji / (bookCounts.cnt_vocab+bookCounts.cnt_kanji+bookCounts.cnt_trash) *100 }%;`"
                    >
                    {{cards.find(i=>i.source == items.vocab_list).cnt_kanji}}
                    </div>
                    <div v-tippy
                        content="Marked as known"
                        class="progress-bar bg-secondary"
                        role="progressbar"
                        :style="`width:${ bookCounts.cnt_trash / (bookCounts.cnt_vocab+bookCounts.cnt_kanji+bookCounts.cnt_trash) *100 }%;`"
                    >
                    {{cards.find(i=>i.source == items.vocab_list).cnt_trash}}
                    </div>
                </div>
            </div>
            <h2 v-if="$bookWordDistribution.arr.length > 0">Distribution</h2>
            <div v-if="$bookWordDistribution.arr.length > 0"
                class="d-flex w-100"
                v-for="(theBook, indx) in $bookWordDistribution.arr.filter(i=>i.title == items.title)"
                :key="indx+'b'"
            >
                <div class="progress marg-big w-100">
                    <div v-tippy content="This book" class="progress-bar bg-info" role="progressbar" style="filter:brightness(1.3)"
                    :style="`width:${ theBook.cnt / $bookWordDistribution.total *100 }%;`"
                    >{{ `${(theBook.cnt / $bookWordDistribution.total *100).toFixed(2)}%` }}</div>
                    <div v-tippy content="All other books" class="progress-bar bg-secondary" role="progressbar" style="filter:brightness(1)"
                    :style="`width:${ ($bookWordDistribution.total-theBook.cnt)/$bookWordDistribution.total*100 }%;`"
                    >{{`Others: ${ (($bookWordDistribution.total-theBook.cnt)/$bookWordDistribution.total*100).toFixed(2) }%`}}</div>
                </div>
            </div>
            <div v-if="items.vocab_list" class="align-right">
                <router-link :to="{ path: '/list', query: { list: items.vocab_list } }">
                    <button class="btn bg-secondary clickable marg-big" >Continue Learning<i class="fas fa-link" style="padding-left:.5em;filter:brightness(.9)"></i></button>
                </router-link>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    created() {
        console.log('%cCreated Cover', window.ConsoleStyles.createdComponent, this)
    },
    name: 'Cover',
    props: [
        'cards',
        'items',
        '$bookWordDistribution'
    ],
    computed: {
        mediumIcon() {
            const { medium } = this.items
            if (medium === 'b') return 'fa-book-open'
            if (medium === 'a') return 'fa-film'
            if (medium === 'd') return 'fa-video'
            if (medium === 'o') return 'fa-archive'
            //  else is a game
            return 'fa-gamepad'
        }
    },
    methods: {
        /*
        getPercent(part, total) {
            return (part / total) * 100
        }
        */
    }
}
</script>

<style lang="sass" scoped>
.cover-icon
    position: absolute
    z-index: 2
    left: 85%
    right: 0
    transform: translateX(-50%)
    margin-top: .5em
    font-size: 110%
    .fa-circle
        text-shadow: 0px 3px 6px #2b324a
@media only screen and (min-width: 768px)
    .cover-icon
        font-size: 125%
</style>
