<template>
<div>
    <h2 class="d-flex align-center" style="justify-content:center;align-items:center;">
        <div class="fa-stack fa d-block align-center">
            <i class="fa fa-circle fa-stack-2x icon-background c-blue"></i>
            <i class="fas fa-stack-1x c-white fa-user"></i>
        </div>
        <div>{{username}}</div>
    </h2>
    <h2>Progress</h2>
    <div class="progress marg-big">
        <div class="progress-bar bg-info"
            v-tippy
            content="Lessons"
            :style="`${getPercent(lessons, moduleTotals)}`"
        >
            {{lessons}}
        </div>
        <div class="progress-bar bg-purple"
            v-tippy
            content="Active words"
            :style="`width:${getPercent(active.vocab, moduleTotals)}%;`"
        >
            {{active.vocab}}
        </div>
        <div class="progress-bar bg-red"
            v-tippy
            content="Active kanji"
            :style="`width:${getPercent(active.kanji, moduleTotals)}%;`"
        >
            {{active.kanji}}
        </div>
        <div class="progress-bar bg-jet"
            v-tippy
            content="Burned Items"
            :style="`width:${getPercent(active.burned, moduleTotals)}%;`"
        >
            {{active.burned}}
        </div>
        <div class="progress-bar bg-secondary"
            v-tippy
            content="Words marked as known"
            :style="`width:${getPercent(trash, moduleTotals)}%;`"
        >
            {{trash}}
        </div>
    </div>
    <h2>Reviews</h2>
    <div class="progress marg-big">
        <div class="progress-bar bg-success"
            v-tippy
            content="Correct Answers"
            :style="`width:${getPercent(correct, moduleReviews)}%;`"
        >
            {{correct}}
        </div>
        <div class="progress-bar bg-warning"
            v-tippy
            content="Incorrect Answers"
            :style="`width:${getPercent(incorrect, moduleReviews)}%;`"
        >
            {{incorrect}}
        </div>
    </div>
    <h2>Distribution</h2>
    <div v-if="$bookWordDistribution && $bookWordDistribution.arr.length !== 0" class="progress marg-big">
        <div
            class="progress-bar bg-purple"
            v-for="(books, indx) in topBooks"
            :key="indx+'a'"
            v-tippy :content="books.title+'<br />'+books.cnt+' items'"
            :style="`width:${ books.percent}%; filter:brightness(${1.3 - (.15 * indx) })`"
        >
            {{books.title}}
        </div>
        <div class="progress-bar bg-purple"
            style="filter:brightness(.9)"
            v-tippy
            :content="'All other books'"
            :style="`width:${ ($bookWordDistribution.total-$bookWordDistribution.topThree)/$bookWordDistribution.total*100}%;`"
        >
            Others
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'User',
    props: {
        username: {
            default: 'Profile'
        },
        trash: {
            default: 0
        },
        lessons: {
            default: 0
        },
        active: {
            default() {
                return {
                    vocab: 0,
                    kanji: 0
                }
            }
        },
        correct: {
            default: 0
        },
        incorrect: {
            default: 0
        },
        $bookWordDistribution: {
            default: null
        }
    },
    computed: {
        moduleTotals() {
            return this.lessons + this.active.total + this.trash
        },
        moduleReviews() {
            return this.correct + this.incorrect
        },
        topBooks() {
            return this.$bookWordDistribution.arr.slice(0, 3)
        }
    },
    methods: {
        getPercent(part, total) {
            return (part / total) * 100
        }
    }
}
</script>

<style lang="sass" scoped>
.progress-bar
    min-width: 4em
</style>
