<template>
<div v-cloak class="container-fluid" id="data_page">
    <div class="row">
        <Spinner
            v-if="!flags.loaded"
            :badge="'bg-success'"
            :message="'Loading'"
        />
        <div
            v-else
            id="main"
            class="col-12 col-md-9 mx-auto"
        >
            <!--alert-->
            <div class="row">
                <User
                    class="col-12 book-card pad-big marg-big"
                    v-if="flags.loaded"
                    v-bind="modules"
                    :$bookWordDistribution="$bookWordDistribution"
                />
            </div>
            <div class="row" v-if="modules.cards && modules.cards.length > 0">
                <Cover
                    class="book-card col-12 marg-big"
                    style="flex-direction:column; display:flex;"
                    v-for="(books, indx) in $bookWordDistribution.arr"
                    :key="indx"
                    :items="$bookToShow.find((i=>i.title === books.title))"
                    :cards="modules.cards"
                    :$bookWordDistribution="$bookWordDistribution"
                />
                <!--  -->
            </div>
        </div>
    </div>
</div>
</template>

<script>
/* eslint-disable camelcase */
import C from '@/assets/common'
import User from './User-Progress.vue'
import Cover from './Cover.vue'
import Spinner from '../Spinner.vue'

const {
    UseAPI, HandleRequestFail, SendUserAlert
} = C

export default {
    name: 'Data',
    components: {
        User,
        Cover,
        Spinner,
    },
    computed: {
        $bookToShow() {
            const { books, $bookWordDistribution } = this
           if (!books || books.length < 0) return []
           const cards = this.modules.cards.map((i) => i.title)
           return books.filter((i, indx) => cards.includes(i.title))
        },
        $bookWordDistribution() {
            const { cards } = this.modules
            if (!cards) {
                return false
            }
            const counts = cards.map((i) => ({ title: i.title, cnt: i.cnt_kanji + i.cnt_vocab }))
            // sort by most first
            counts.sort((a, b) => ((a.cnt < b.cnt) ? 1 : -1))
            let total = 0
            let topThree = 0
            counts.forEach((i) => { total += i.cnt })
            counts.slice(0, 3).forEach((i) => { topThree += i.cnt })
            // get percent of pie
            counts.slice(0, 3).forEach((i) => {
                i.percent = (i.cnt / total) * 100
            })
            // return total and top 3 books
            return {
                total,
                topThree,
                //  arr: counts
                arr: counts.filter((i) => i.cnt > 0)
            }
        }
    },
    methods: {
        init(dat) {
            function prepBook(bookItem) {
                // set wtr/fav flags
                if (typeof bookItem.flag === 'string') {
                    bookItem.flag = bookItem.flag.split(',')
                } else {
                    bookItem.flag = []
                }
                // set tags
                // set filter visibility
                bookItem.hidden = false
            }
            // modify ajax and push to vue
            const items = dat.items;
            for (let i = 0; i < items.length; i += 1) {
                prepBook(items[i])
                this.books.push(items[i])
            }
            this.flags.loaded = true
            this.counts.total = this.books.length
            this.counts.showing = this.books.length

            // handle modules
            const { modules } = dat
            const {
                cnt_kanji = 0,
                cnt_lessons = 0,
                cnt_trash = 0,
                cnt_vocab = 0,
                cnt_burned = 0,
            } = modules.active[0]
            const { correct = 0, incorrect = 0 } = modules.accuracy
            const { cards = [] } = modules
            const { username = "" } = dat.options
            //  accuracy
            this.modules.correct = correct
            this.modules.incorrect = incorrect
            //  word counts
            this.modules.trash = cnt_trash
            this.modules.active = {
                kanji: cnt_kanji,
                vocab: cnt_vocab,
                burned: cnt_burned,
                total: cnt_kanji + cnt_vocab + cnt_burned
            }
            this.modules.lessons = cnt_lessons
            //  cards
            this.modules.cards = cards
            this.modules.username = username
            return true
        },
        goToListPage(indx) {
            const bookList = this.books[indx].vocab_list;
            window.location = `${window.location.origin}/list?list=${bookList}`;
        },
    },
    data() {
        return {
            books: [],
            opts: {
                freq: 0,
                wk: 0,
                lists: []
            },
            flags: {
                loaded: false,
                error: false,
                err_msg: '',
                modified: false,
                made: false,
                debug: true,
                modalIndex: 0,
                showing: 30,
            },
            filters: [],
            useFilters: {
                medium: 'any',
                difficulty: 'any',
                wtr: false,
                fav: false,
                private: false,
                search: ""
            },
            modules: {
                cards: [],
                trash: 0,
                lessons: 0,
                active: 0,
                username: "",
                correct: 0,
                incorrect: 0,
            },
            counts: {
                showing: 0,
                total: 0,
            },
        }
    },
    created() {
        console.log("%cCreated Data", window.ConsoleStyles.createdComponent, this)
        const GetData = () => UseAPI('/get/data', { method: "POST" })

        GetData()
        .then((res) => this.init(res))
        .catch((res) => {
            console.log("Error getting books")
            return HandleRequestFail(res)
        })
    }
}
</script>

<style lang="sass">
#data_page
    .progress-bar
        min-width: 3em
    .fa-2x.fa-circle
</style>
